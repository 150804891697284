<template>
  <div>
    <div class="home" data-id="00">
      <div v-if="false" class="home_pcindex1">
        <div class="home_pcindex1lb">
          <el-carousel
            height="100vh"
            :loop="loop"
            :interval="time"
            indicator-position="none"
            arrow="never"
          >
            <el-carousel-item v-for="item in lb1" :key="item">
              <div class="home_pcindex1lbimg">
                <img v-lazy="item.src" alt="" />
                <div class="home_pcindex1imglbtext">
                  <div class="home_pcindex1imglbtextmoney">
                    花费<span>￥{{ item.money }}</span> 用时<span>{{
                      item.day
                    }}</span
                    >天
                  </div>
                </div>
                <div class="home_pcindex1imglbtext">
                  <div class="home_pcindex1imglbtextname">{{ item.name }}</div>
                </div>
                <div class="home_pcindex1imglbtext">
                  <div class="home_pcindex1imglbtext_title">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="home_pcindex1all">
          <div class="home_pcindex1allleft">
            <img
              src="https://task.hozoin.cn/Works/20201214/sj-5538343714.png"
              alt=""
            />
          </div>
        </div>
        <div class="home_pcindex1allbtmr">
          <router-link to="/Chengguo">
            <div>
              寻找创意设计
              <p>
                <img
                  src="https://task.hozoin.cn/Works/20201214/sj-8663520035.png"
                  alt=""
                />
              </p>
            </div>
          </router-link>
          <router-link to="/Xiangmu">
            <div>
              赚取设计奖励
              <p>
                <img
                  src="https://task.hozoin.cn/Works/20201214/sj-8663520035.png"
                  alt=""
                />
              </p>
            </div>
          </router-link>
        </div>
        <div class="home_pcindex1allbtmr_e22"></div>
        <div class="home_pcindex1allbtml">
          <div class="home_pcindex1allbtmlbox">
            <div class="home_pcindex1allbtmlimg">
              <img
                id="znx_nhome"
                src="https://task.hozoin.cn/Works/20201116/sj-5183273169.png"
                alt=""
              />
            </div>
            <el-carousel
              indicator-position="none"
              :interval="time"
              direction="vertical"
            >
              <el-carousel-item
                v-for="(item, index) in home_pcindex1allbtmlboxtext"
                :key="index"
              >
                <p class="home_pcindex1allbtmlboxtext">
                  <router-link :to="item.u">{{ item.text }}</router-link>
                </p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="home_pcindex111">
        <div class="home_pcindex111lbbox">
          <el-carousel height="82vh" indicator-position="none" :interval="time">
            <el-carousel-item
              v-for="item in home_pcindex111lbbox"
              :key="item.src"
            >
              <router-link :to="item.a">
                <img class="home_pcindex111lbboximg" :src="item.src" alt="" />
              </router-link>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="home_pcindex1allbtmr">
          <router-link to="/Chengguo">
            <div>
              寻找创意设计
              <p>
                <img
                  src="https://task.hozoin.cn/Works/20201214/sj-8663520035.png"
                  alt=""
                />
              </p>
            </div>
          </router-link>
          <router-link to="/Xiangmu">
            <div>
              赚取设计奖励
              <p>
                <img
                  src="https://task.hozoin.cn/Works/20201214/sj-8663520035.png"
                  alt=""
                />
              </p>
            </div>
          </router-link>
        </div>
        <div class="home_pcindex1allbtmr_e22"></div>
        <div class="home_pcindex1allbtml">
          <div class="home_pcindex1allbtmlbox">
            <div class="home_pcindex1allbtmlimg">
              <img
                id="znx_nhome"
                src="https://task.hozoin.cn/Works/20201116/sj-5183273169.png"
                alt=""
              />
            </div>
            <el-carousel
              indicator-position="none"
              :interval="time"
              direction="vertical"
            >
              <el-carousel-item
                v-for="(item, index) in home_pcindex1allbtmlboxtext"
                :key="index"
              >
                <p class="home_pcindex1allbtmlboxtext">
                  <router-link :to="item.u">{{ item.text }}</router-link>
                </p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="home_pcindex2">
        <div class="home_pcindex2_c">
          <div class="home_pcindex2_cl">
            <p class="home_pcindex2_clp1">平台服务</p>
            <p class="home_pcindex2_clp2">成果与专利转化，助成长促就业</p>
            <ul class="home_pcindex2_clul">
              <li
                class="home_pcindex2_clli"
                v-for="item in home_pcindex2_clul"
                :key="item.s1"
                @click="ptclick"
              >
                <span class="home_pcindex2_cls1">{{ item.s1 }}</span>
                <span class="home_pcindex2_cls2">{{ item.s2 }}</span>
                <span class="home_pcindex2_cls3">
                  <icon-svg icon-class="iconyou1" />
                </span>
              </li>
            </ul>
          </div>
          <div class="home_pcindex2_cr">
            <img :src="home_pcindex2_crimg" alt="" />
          </div>
        </div>
        <div class="home_pcindex2_rb">
          <div>接单</div>
          <p>Receiving orders</p>
          <div></div>
          <p>
            面向学生的真实社会项目，中标几率大，锻炼机会多，从设计到完成全过程实践，让设计不再停留在理论中
          </p>
        </div>
        <img
          class="home_pcindex2_imgb"
          src="https://task.hozoin.cn/Works/20210407/sj-0500335781.png"
          alt=""
        />
      </div>
      <div class="home_pcindex3c">
        <p class="home_pcindex3cp1">平台优势</p>
        <p class="home_pcindex3cp2">全国首家产教融合一站式设计服务平台</p>
        <div class="home_pcindex3cxian"></div>
        <ul class="home_pcindex3cul">
          <li
            v-for="item in home_pcindex3cul"
            :key="item.url"
            class="home_pcindex3cli"
          >
            <div class="home_pcindex3cliimg">
              <img :src="item.url" alt="" />
            </div>
            <div class="home_pcindex3cli_r">
              <p>{{ item.t }}</p>
              <p>{{ item.p }}</p>
            </div>
          </li>
        </ul>
        <img
          src="https://task.hozoin.cn/Works/20210408/sj-2307116221.gif"
          class="home_pcindex3limg"
          alt=""
        />
      </div>
      <div class="home_pcindex4">
        <div class="home_pcindex4l">
          <p>成果案例</p>
          <p>
            搭建企业与学校强大生态链，帮助企业解决设计费用高，学生缺少社会实践项目问题
          </p>
        </div>
        <ul class="home_pcindex4r">
          <li
            class="home_pcindex4rli"
            v-for="item in home_pcindex4r"
            :key="item.id"
            @click="cglink(item.id)"
          >
            <div class="home_pcindex4rliimg">
              <video
                controls="controls"
                v-if="item.video != ''"
                :src="item.video"
              ></video>
              <img v-if="item.video == ''" :src="item.src" alt="" />
            </div>
            <p class="home_pcindex4rlip1">
              任务赏金<span>￥{{ item.money }}</span>
            </p>
            <p class="home_pcindex4rlip2">
              <span>{{ item.school }}</span
              ><span>{{ item.name }}获得</span>
            </p>
            <div class="home_pcindex4rlidiv1"></div>
            <p class="home_pcindex4rlip3">
              {{ item.gs }}
            </p>
            <div class="home_pcindex4rlidiv2">
              <icon-svg icon-class="iconyou1" />
            </div>
          </li>
        </ul>
        <img
          class="home_pcindex4icon"
          src="https://task.hozoin.cn/Works/20210407/sj-7799286093.png"
          alt=""
        />
      </div>
      <div class="home_pcindex5">
        <p class="home_pcindex5tp1">专家指导</p>
        <p class="home_pcindex5tp2">多领域专家资源的跨界与融合</p>
        <div class="home_pcindex5lb">
          <el-carousel
            height="32rem"
            :interval="time"
            :loop="loop"
            arrow="never"
            indicator-position="outside"
          >
            <el-carousel-item
              v-for="(item, index) in lb5"
              v-show="index < 3"
              :key="index"
            >
              <div class="home_pcindex5lbbox">
                <div class="home_pcindex5lbbox_img">
                  <a :href="item.url">
                    <img v-lazy="item.titlepic" alt="" />
                  </a>
                </div>
                <div class="home_pcindex5lbbox_r">
                  <div class="home_pcindex5lbbox_rtop">
                    {{ item.said }}
                  </div>
                  <div class="home_pcindex5lbbox_rbtm">
                    <div class="home_pcindex5lbbox_rbtmname">
                      <a :href="item.url">
                        {{ item.title }}
                      </a>
                    </div>
                    <div class="home_pcindex5lbbox_rbtmtext">
                      {{ item.fbtitle }}
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="home_pcindex6">
        <p class="home_pcindex6h2">支持共建</p>
        <div class="home_pcindex6box">
          <el-tabs v-model="activeName" @tab-click="schoolqy">
            <el-tab-pane label="政府" name="zf">
              <ul class="home_pcindex6boxul">
                <li v-for="(item, index) in schoolall" :key="index">
                  <div class="home_pcindex6boxulimg">
                    <img v-lazy="item.lpic" :alt="item.lname" />
                  </div>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="学校" name="school">
              <ul class="home_pcindex6boxul">
                <li v-for="(item, index) in schoolall" :key="index">
                  <div class="home_pcindex6boxulimg">
                    <img v-lazy="item.lpic" :alt="item.lname" />
                  </div>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="企业" name="qy">
              <ul class="home_pcindex6boxul">
                <li v-for="(item, index) in schoolall" :key="index">
                  <div class="home_pcindex6boxulimg">
                    <img v-lazy="item.lpic" :alt="item.lname" />
                  </div>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="home_ios">
      <div class="home_ios_topbox">
        <div class="home_ios_topboxbox">
          <img
            class="home_ios_topboxboximg1"
            src="https://task.hozoin.cn/Works/20210429/sj-3949672322.png"
            alt=""
          />
          <!-- <img
            class="home_ios_topboxboximg1"
            src="https://task.hozoin.cn/Works/20201223/sj-5340514793.png"
            alt="Z图"
          /> -->
          <img
            src="https://task.hozoin.cn/Works/20210429/sj-0212285624.png"
            class="home_ios_topboxboximg2"
            alt=""
          />
          <!-- <img class="home_ios_topboxboximg3" src="https://task.hozoin.cn/Works/20201223/sj-5010883966.png" alt="Y图"> -->
          <video
            id="myvideo"
            autoplay
            muted
            loop="loop"
            x5-playsinline="true"
            playsinline="true"
            webkit-playsinline="true"
          >
            <source
              src="https://img.hozoin.cn/video/20220315/57f23d59aa3b6a13bc417031d737c3ab.mp4"
              type="video/mp4"
            />
            <!-- https://img.hozoin.cn/video/20210429/b19e6310903186432f6a76a314154283.mp4 -->
          </video>
          <div class="home_ios_topboxboxtext" id="home_ios_topboxboxtext">
            <div class="home_ios_topboxboxtext2" id="home_ios_topboxboxtext2">
              <span>2 0 2 2 年全国高等院校线上毕业设计展火热报名中</span>
              <span>第四届 泰山设计杯 山东手造创新设计大赛进行中</span>
              <!--<span class="sp1">上海工程技术大学</span>
              <span class="sp2">2 0 2 1艺术设计学院毕业设计展进行中！</span>
              <span class="sp3">2 0 2 1 全国高等院校线上毕设展 火热报名中！</span> -->
            </div>
            <div
              class="home_ios_topboxboxtext2"
              id="home_ios_topboxboxtext3"
            ></div>
            <div
              class="home_ios_topboxboxtext2"
              id="home_ios_topboxboxtext4"
            ></div>
          </div>
        </div>
        <div class="home_ios_topboxl">
          <router-link to="/Zhanping">参展</router-link>
        </div>
        <div class="home_ios_topboxr">
          <router-link to="/Xiangmu">接单</router-link>
        </div>
      </div>
      <div class="home_ios_bottombox">
        <div class="home_ios_bottomboxbk"></div>
        <div class="home_ios_bottombox_title">
          全国首家产教融合一站式设计服务平台
        </div>
        <ul class="home_ios_bottombox_4box">
          <li class="home_ios_bottombox_4li">
            <img
              src="https://task.hozoin.cn/Works/20210425/sj-7347972066.png"
              alt=""
            />
            <router-link to="/Rencai"> </router-link>
          </li>
          <li class="home_ios_bottombox_4li">
            <img
              src="https://task.hozoin.cn/Works/20210425/sj-1060707332.png"
              alt=""
            />
            <router-link to="/Chengguo"> </router-link>
          </li>
          <li class="home_ios_bottombox_4li">
            <img
              src="https://task.hozoin.cn/Works/20210425/sj-6247843806.png"
              alt=""
            />
            <router-link to="/Jiaoyu"> </router-link>
          </li>
          <li class="home_ios_bottombox_4li">
            <img
              src="https://task.hozoin.cn/Works/20210425/sj-1216987670.png"
              alt=""
            />
            <router-link to="/Zixun"> </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      home_pcindex111lbbox: [
        {
          src: "https://img.hozoin.cn/news_img/20221123/16692032160001568.jpg",
          a: "/Zhanping",
        },
        {
          src: "https://img.hozoin.cn/news_img/20230214/16763845610001568.jpg",
          a: "/Zhanping",
        },
        {
          src: "https://task.hozoin.cn/Works/20210421/sj-4547998789.png",
          a: "/Xiangmu",
        },
        {
          src: "https://task.hozoin.cn/Works/20210421/sj-0336476993.png",
          a: "/Xiangmu",
        },
      ],
      iosl: "https://task.hozoin.cn/Works/20201229/sj-5460637137.jpg",
      iosr:
        "https://shijian-oss.oss-cn-beijing.aliyuncs.com/news_img/202007/2020070993901.jpg",
      isocenter: "https://task.hozoin.cn/Works/20201223/sj-2356474475.png",
      userinfo: {},
      lb1: [
        {
          src: "https://task.hozoin.cn/Works/20201208/sj-2656873732.jpg",
          title: "未豆Vtooth冲牙器产品包装设计",
          name: "该设计来自 上海工程技术大学-董美玲",
          money: "2800",
          day: "14",
        },
        {
          src: "https://task.hozoin.cn/Works/20201214/sj-0876265131.jpg",
          title: "领航果汁机外观设计",
          name: "该设计来自 上海工程技术大学-胡蜜蜜",
          money: "7500",
          day: "31",
        },
        {
          src: "https://task.hozoin.cn/Works/20201210/sj-7413010645.jpg",
          title: "百亩园野生猕猴桃酒包装系统设计",
          name: "该设计来自 上海工程技术大学-言一",
          money: "5000",
          day: "31",
        },
        {
          src: "https://task.hozoin.cn/Works/20201210/sj-6972261890.jpg",
          title: "人员位置终端产品设计",
          name: "该设计来自 上海工程技术大学-董美玲",
          money: "4000",
          day: "14",
        },
      ],
      activeName: "zf",
      schoolall: [],
      lb5: [],
      time: 3000,
      loop: true,
      home_pcindex2_clul: [
        {
          s1: "01",
          s2: "接项目得赏金",
        },
        {
          s1: "02",
          s2: "逛展览扩视野",
        },
        {
          s1: "03",
          s2: "卖创意获报酬",
        },
        {
          s1: "04",
          s2: "在线学助提升",
        },
        {
          s1: "05",
          s2: "建简历找工作",
        },
      ],
      home_pcindex3cul: [
        {
          url: "https://task.hozoin.cn/Works/20210407/sj-5732836719.png",
          t: "产学·融合·共建",
          p:
            "国内首家政府、高校、企业合作设计与管理创新平台，是上海市文创办、上海市教委、上海市财政扶持的文化引领产业项目",
        },
        {
          url: "https://task.hozoin.cn/Works/20210407/sj-7811396765.png",
          t: "合作·造就·共建",
          p:
            "引入社会项目，通过有偿的设计服务，让高校师生在社会项目的真实情境中实践、积累和成长，使设计落地、产品优化，助推设计教育改革、社会就业和创新。",
        },
        {
          url: "https://task.hozoin.cn/Works/20210407/sj-9895915922.png",
          t: "跨界·设计·创新",
          p:
            "作品展览与交流、成果与专利转化，产生聚合效应和设计价值。平台发展并成为多专业交叉与跨界融合的国际化设计创新平台。",
        },
      ],
      home_pcindex4r: [
        {
          src: "https://task.hozoin.cn/Works/20201229/sj-9297711260.jpg",
          video:
            "https://img.hozoin.cn/video/20210427/6f1892e5be2768a3e3094746bf0dfb38.mp4",
          money: "5000",
          school: "上海工程技术大学",
          name: "岳言",
          gs: "百亩园野生猕猴桃酒包装系统设计",
          id: "108",
        },
        {
          video: "",
          src: "https://task.hozoin.cn/Works/20201229/sj-5460637137.jpg",
          money: "7500",
          school: "上海工程技术大学",
          name: "胡蜜蜜",
          gs: "保利协鑫能源控股有限公司宣传册设计",
          id: "128",
        },
      ],
      home_pcindex2_crimg:
        "https://task.hozoin.cn/Works/20210423/sj-8340788355.jpg",
      home_pcindex1allbtmlboxtext: [
        {
          text: "明龙户外头灯包装设计及视觉延展项目入围中标公示",
          u: "/Zixun_C?data=159",
        },
        {
          text: "领航Q5咖啡机外观设计项目入围中标公示",
          u: "/Zixun_C?data=72",
        },
      ],
      ptclicknum: 0,
    };
  },
  methods: {
    schoolqy(tab, event) {
      var that = this;
      var index = Number(tab.index) + 1;
      that.schoolall = [];
      if (index == 1) {
        index = 3;
      } else if (index == 2) {
        index = 1;
      } else if (index == 3) {
        index = 2;
      } else {
        index = 3;
      }
      this.$axios({
        url: "/api/getEcmsLinkList",
        method: "post",
        data: {
          classid: index,
          page: "1",
          limit: "3",
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.schoolall = res.data.result.list;
        } else {
        }
      });
    },
    zhuanjiaaxios() {
      var that = this;
      this.$axios({
        url: "/api/getExperts",
        method: "post",
        data: {
          page: "1",
          limit: "3",
          h_top: "1",
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lb5 = res.data.result.list;
        } else {
        }
      });
    },
    cglink(id) {
      this.$router.push({ path: "/Xiangmu_C", query: { data: id } });
    },
    ptnum(id) {
      this.ptclicknum = id;
    },
    ptclick(id) {
      var id = this.ptclicknum;
      if (id == 1) {
        this.$router.push({ path: "/Zhanping" });
      } else if (id == 2) {
        this.$router.push({ path: "/Chengguo" });
      } else if (id == 3) {
        this.$router.push({ path: "/Jiaoyu" });
      } else if (id == 4) {
        var userinfo = JSON.parse(localStorage.getItem("userinfo"));
        this.tokentimeyz();
        if (userinfo == null) {
          this.$router.push({ path: "/Sjlogin" });
        } else {
          this.$router.push({ path: "/Userhome/Userhl_jianli" });
        }
      } else {
        this.$router.push({ path: "/Xiangmu" });
      }
    },
  },
  mounted() {
    document.addEventListener(
      "WeixinJSBridgeReady",
      function() {
        document.getElementById("myvideo").play();
      },
      false
    );
    var imgpt = [
      {
        src: "https://task.hozoin.cn/Works/20210423/sj-8340788355.jpg",
      },
      {
        src:
          "https://img.hozoin.cn/news_img/20210420/3ffc322a8acfdca293a57c3ba5a3d523.png",
      },
      {
        src:
          "https://img.hozoin.cn/news_img/20210420/d99fd3644f9ddcfbccbfc63593a419e2.png",
      },
      {
        src:
          "https://img.hozoin.cn/news_img/20210420/f8a8dbdcb867c2429066c4bc94afc266.png",
      },
      {
        src:
          "https://img.hozoin.cn/news_img/20210420/e4ecee54057d9842367b7c56e5e1b6bc.png",
      },
    ];
    var textpt = [
      {
        en: "Receiving orders",
        tit: "接单",
        tall:
          "面向学生的真实社会项目，中标几率大，锻炼机会多，从设计到完成全过程实践，让设计不再停留在理论中。",
        btn: "立即接单",
      },
      {
        en: "Exhibition",
        tit: "展览",
        tall:
          "随时随地掌上看展，足不出户看遍世界顶级创意。入驻便宜曝光高，知识产权受保护。",
        btn: "观看展览",
      },
      {
        en: "Selling ideas",
        tit: "卖创意",
        tall: "售卖原创作品，完成设计创意成果转化，丰富个人作品利于找工作",
        btn: "立即变现",
      },
      {
        en: "Curriculum",
        tit: "课程",
        tall: "观看各大名校教师讲课，不上名校同样学习，把握每一个学习机会",
        btn: "我要学习",
      },
      {
        en: "Resume",
        tit: "简历",
        tall: "展现个人设计作品、简历便于企业方雇佣",
        btn: "创建简历",
      },
    ];
    var that = this;
    $(document).on("mouseenter", ".home_pcindex2_clul li", function() {
      var index = $(this).index();
      $(".home_pcindex2_cr img").attr("src", imgpt[index].src);
      $(".home_pcindex2_clul li")
        .eq(index)
        .css({
          color: "#fc9732",
        });
      $(".home_pcindex2_clul li")
        .eq(index)
        .find(".home_pcindex2_cls1")
        .css({
          color: "#fc9732",
        });
      $(".home_pcindex2_clul li")
        .eq(index)
        .find(".home_pcindex2_cls3")
        .css({
          display: "flex",
        });
      $(".home_pcindex2_clul li")
        .eq(index)
        .siblings()
        .css({
          color: "#333",
        });
      $(".home_pcindex2_clul li")
        .eq(index)
        .siblings()
        .find(".home_pcindex2_cls1")
        .css({
          color: "#999",
        });
      $(".home_pcindex2_clul li")
        .eq(index)
        .siblings()
        .find(".home_pcindex2_cls3")
        .css({
          display: "none",
        });
      $(".home_pcindex2_rb div:nth-child(1)").text(textpt[index].tit);
      $(".home_pcindex2_rb p:nth-child(2)").text(textpt[index].en);
      $(".home_pcindex2_rb p:nth-child(4)").text(textpt[index].tall);
      $(".home_pcindex2_rb div:nth-child(5)").text(textpt[index].btn);
      that.ptnum(index);
    });
    document.onscroll = function() {
      var t1 = document.documentElement.scrollTop || document.body.scrollTop; //滚动条 距顶距离
      if (t1 > 500) {
        if (t1 > 1400) {
          $(".home_pcindex2_rb").css({
            animation: "home_pcindex2_rbmove0 1s",
            "animation-fill-mode": "forwards",
          });
        } else {
          $(".home_pcindex2_rb").css({
            animation: "home_pcindex2_rbenter0 1s",
            "animation-fill-mode": "forwards",
          });
        }
        if (t1 > 1000) {
          $(".home_pcindex3limg").css({
            animation: "home_pcindex3limgenter 1s",
            "animation-fill-mode": "forwards",
          });
          if (t1 > 2000) {
            $(".home_pcindex3limg").css({
              animation: "home_pcindex3limgout 1s",
              "animation-fill-mode": "forwards",
            });
          }
        } else {
          $(".home_pcindex3limg").css({
            animation: "home_pcindex3limgout 1s",
            "animation-fill-mode": "forwards",
          });
        }
      } else {
        $(".home_pcindex2_rb").css({
          animation: "home_pcindex2_rbmove0 1s",
          "animation-fill-mode": "forwards",
        });
      }
    };
    var speed = 60; //数字越大越慢
    var demo = document.getElementById("home_ios_topboxboxtext");
    var demo1 = document.getElementById("home_ios_topboxboxtext2");
    var demo2 = document.getElementById("home_ios_topboxboxtext3");
    var demo3 = document.getElementById("home_ios_topboxboxtext4");
    demo2.innerHTML = demo1.innerHTML;
    demo3.innerHTML = demo1.innerHTML;
    var offhe = demo1.offsetHeight;
    function marquee() {
      if (demo.scrollTop >= offhe + 100) {
        demo.scrollTop = 0;
      } else {
        demo.scrollTop = demo.scrollTop + 1;
      }
    }
    setInterval(marquee, speed);
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.schoolqy(0);
    this.zhuanjiaaxios();
    this.fenxiang(
      "和作营",
      "全国首家产教融合一站式设计服务平台。",
      "https://mmbiz.qpic.cn/mmbiz_png/vBjAjicW4DmC6McbmE18LUoeX3ibDAIiawYO72fKWibdSgaqfus1j5WxJR0SN7nOgjNFjGsmk3jPqecG1rqOsUxTzg/0?wx_fmt=png"
    );
  },
};
</script>
<style>
@import "../assets/css/home.css";
</style>
